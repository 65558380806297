<ng-container *ngIf="this.walletService.monitoring | async as monitoring">
  <ng-container *ngIf="!monitoring.empty">
    <div class="m-modal-bg" (click)="close($event)"></div>
    <div class="m-modal-container">
      <div>
        <div class="row">
          <div class="d-flex justify-content-center align-items-center col-12"><img src="assets/logo-black.svg" width="200px"></div>
          <div class="d-flex justify-content-center align-items-center mt-3 col-12">
            <div class="text-danger font-size-24 font-w600"> {{ monitoring.title }} </div>
          </div>
          <div class="d-flex justify-content-center align-items-center mt-3 col-12">
            <div class="text-light font-w400"> {{ monitoring.content }} </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- <ng-container *ngIf="monitoring.loading">
    <div class="m-modal-bg" (click)="close($event)"></div>
    <div class="m-modal-container">
      <div>
        <div class="row">
          <div class="d-flex justify-content-center align-items-center col-12"><img src="assets/logo-black.png" width="200px"></div>
          <div data-v-4e6349ca="" class="d-flex justify-content-center align-items-center col-12 mt-3"><span data-v-4e6349ca="" role="status" class="align-self-center align-middle spinner-border text-primary-light" style="display: block; width: 2rem; height: 2rem;"><span data-v-4e6349ca="" class="sr-only">Loading... </span></span><h5 data-v-4e6349ca="" class="m-0 ml-3 text-muted-light">Just a moment...</h5></div>
        </div>
      </div>
    </div>
  </ng-container> -->
</ng-container>