<button *ngIf="($wallet | async) as wallet" type="button" class="btn block-rounded btn-sm mr-2" [dark]="['btn-outline-dark']" [light]="['btn-outline-light']" appTheme>
    <span class="d-none d-sm-inline mr-2">{{ CDTamount }} CDT</span>
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="power-off" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124 124" class="svg-inline--fa fa-power-off fa-w-16 fa-fw" style="transform: translate(0, -2px);">
        <path d="M86.2234+35.533C75.811+50.4746+62.7483+78.1118+57.7314+88.8541C58.8303+94.6209+59.7772+100.417+60.5712+106.237C59.5299+102.819+57.3528+97.2527+55.649+93.2487L55.649+93.3464C55.649+93.3464+55.649+93.1511+55.4596+92.6628L53.6611+88.4635C52.5253+86.022+50.7268+82.311+47.887+76.9399C29.7128+45.0058-0.104364+63.6584-0.104364+63.6584C4.72317+66.8811+15.4195+84.6548+19.7737+95.5925C24.128+106.53+30.47+122.839+42.8702+129.284C59.9086+136.902+71.7408+129.48+78.7454+122.644C78.3647+121.179+78.1737+119.668+78.1775+118.151C78.1775+109.46+84.4249+102.331+92.4708+101.159L120.395+44.4198C131.47+22.6421+145.952+14.9272+145.952+14.9272C116.703+3.20822+99.4754+16.5873+86.2234+35.533Z" opacity="1" fill="#31f793"/>
        <path d="M76.5279+118.086C76.5279+107.727+84.6678+99.3287+94.7088+99.3287C104.75+99.3287+112.89+107.727+112.89+118.086C112.89+128.445+104.75+136.843+94.7088+136.843C84.6678+136.843+76.5279+128.445+76.5279+118.086Z" opacity="1" fill="#31f793"/>
    </svg>
</button>

<button *ngIf="($wallet | async) as wallet; else offline" (click)="disconnect()" type="button" class="btn block-rounded btn-sm" [dark]="['btn-outline-dark']" [light]="['btn-outline-light']" appTheme>
    <span class="d-none d-sm-inline mr-2">{{ wallet.address | slice:0:11 }}...{{ wallet.address | slice:31:42 }}</span>
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="power-off" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-power-off fa-w-16 fa-fw">
        <path fill="currentColor" d="M400 54.1c63 45 104 118.6 104 201.9 0 136.8-110.8 247.7-247.5 248C120 504.3 8.2 393 8 256.4 7.9 173.1 48.9 99.3 111.8 54.2c11.7-8.3 28-4.8 35 7.7L162.6 90c5.9 10.5 3.1 23.8-6.6 31-41.5 30.8-68 79.6-68 134.9-.1 92.3 74.5 168.1 168 168.1 91.6 0 168.6-74.2 168-169.1-.3-51.8-24.7-101.8-68.1-134-9.7-7.2-12.4-20.5-6.5-30.9l15.8-28.1c7-12.4 23.2-16.1 34.8-7.8zM296 264V24c0-13.3-10.7-24-24-24h-32c-13.3 0-24 10.7-24 24v240c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24z" class=""></path>
    </svg>
</button>

<ng-template #offline>
    <button (click)="connect()" type="button" class="btn block-rounded btn-sm" [dark]="['btn-outline-dark']" [light]="['btn-outline-light']" appTheme>
        <span class="d-none d-sm-inline mr-2">Connect Wallet</span>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-circle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-arrow-circle-right fa-w-16 fa-fw">
            <path fill="currentColor" d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm-28.9 143.6l75.5 72.4H120c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24h182.6l-75.5 72.4c-9.7 9.3-9.9 24.8-.4 34.3l11 10.9c9.4 9.4 24.6 9.4 33.9 0L404.3 273c9.4-9.4 9.4-24.6 0-33.9L271.6 106.3c-9.4-9.4-24.6-9.4-33.9 0l-11 10.9c-9.5 9.6-9.3 25.1.4 34.4z" class=""></path>
        </svg>
    </button>
</ng-template>